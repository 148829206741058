import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { auth } from './firebase.js'; // adjust the path to the firebase.js file as needed

export const AnonymousMenu = ({ setModalOpen }) => {
    const [buttonColor, setButtonColor] = useState('');
    const database = getDatabase();
    const userId = auth.currentUser.uid;
    const wRef = ref(database, 'w');
    const handleClick = () => {
        console.log("Button clicked");
        setModalOpen(true);
    };

    // This effect runs once on mount and sets up the event listener,
    // and then returns a cleanup function that runs on unmount.
    useEffect(() => {
        const handleValueChange = (snapshot) => {
            const wValue = snapshot.val();
            // Checking 'ha' subnode
            if (wValue && wValue.ha) {
                setButtonColor(wValue.ha === userId ? 'green' : 'blue');
            } else {
                setButtonColor('');
            }
            // You can add similar checks for 'fa' and 'ra' here
        };

        onValue(wRef, handleValueChange);

        // Cleanup function to remove the listener when the component unmounts.
        return () => off(wRef, 'value', handleValueChange);
    }, [wRef, userId]);

    return (
        <div className="App">
            <button className="App-button" style={{ backgroundColor: buttonColor }}>High Chess</button>
            <button className="App-button">Free Chess</button>
            <button className="App-button">Choose Free Army</button>
            <button className="App-button">Royal Chess</button>
            <button className="App-button">Choose Royal Army</button>
            <button className="App-button--large" onClick={handleClick}>
                Register / Log in
            </button>
        </div>
    );
}
