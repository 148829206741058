import React from 'react';
import { getDatabase, ref, remove, set, serverTimestamp } from 'firebase/database';
import { signOut } from 'firebase/auth'; // Import signOut from firebase/auth
import { auth } from './firebase.js'; // Adjust the path to the firebase.js file as needed

export const SignOutButton = ({ onSignOut }) => {
    const database = getDatabase();
    
    const handleSignOut = async () => {
      try {
        await onSignOut(); // using the onSignOut prop function
        console.log('User signed out');
      } catch (error) {
        console.error('Error signing out: ', error);
      }
    }
  
    return (
      <button onClick={handleSignOut}>
        Sign Out
      </button>
    );
};
