import logo from './logo.svg';
import React from 'react';
import './App.css';
import { useAuthenticate, Authenticate } from './authenticate'; 

function App() {
  const authenticationComponent = <Authenticate />;

  return <>{authenticationComponent}</>
}

export default App;
