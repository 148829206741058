
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA76WamHUAl_wi9agdmCZD4dJkBnBYtIGE",
    authDomain: "bovinechess.firebaseapp.com",
    databaseURL: "https://bovinechess-default-rtdb.firebaseio.com",
    projectId: "bovinechess",
    storageBucket: "bovinechess.appspot.com",
    messagingSenderId: "381624798296",
    appId: "1:381624798296:web:b12ea11077d56b0d0cce2d"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app);

export { app, auth, database, storage };
