import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signInAnonymously, signOut } from 'firebase/auth';
import { auth } from './firebase.js';
import { getDatabase, ref, set, remove, serverTimestamp, onDisconnect } from 'firebase/database';
import { AnonymousMenu } from './AnonymousMenu';
import { SignOutButton } from './SignOutButton';
import { SignInModal } from './SignInModal';

// This is your custom hook that will handle authentication logic
export function useAuthenticate() {
    const [userState, setUserState] = useState(null);
    const [modalOpen, setModalOpen] = useState(false); 
    const database = getDatabase();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setUserState(user);
            if (user) {
                const presenceRef = ref(database, `p/n/${user.uid}`);
                set(presenceRef, serverTimestamp());

                // Check if it's not an anonymous user
                if (!user.isAnonymous) {
                    // when disconnected remove presenceRef and push to p/f
                    const onDisconnectRef = ref(database, `p/n/${user.uid}`).onDisconnect();
                    onDisconnectRef.remove(err => {
                        if (err !== null) {
                            console.error(err);
                        } else {
                            const offlineRef = ref(database, `p/f/${user.uid}`);
                            set(offlineRef, serverTimestamp());
                        }
                    });
                }
            } else {
                signInAnonymously(auth);
            }
            // if user is authenticated and not anonymous, close the modal
            if (user && !user.isAnonymous) {
                setModalOpen(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    const handleSignOut = async () => {
        if (auth.currentUser) { // check if there is a current user
            const uid = auth.currentUser.uid;
            const presenceRef = ref(database, `p/n/${uid}`);
            await remove(presenceRef);
            const offlineRef = ref(database, `p/f/${uid}`);
            await set(offlineRef, serverTimestamp());
            await signOut(auth);
        }
    }

    return { userState, modalOpen, setModalOpen, handleSignOut };
}

// This is your component that uses the hook and returns the JSX elements
export function Authenticate() {
    const { userState, modalOpen, setModalOpen, handleSignOut } = useAuthenticate();

    console.log("Modal open state in Authenticate:", modalOpen);

    // Render the AnonymousMenu, SignOutButton or SignInModal based on the state
    if (userState && userState.isAnonymous) {
        return (
            <>
                <AnonymousMenu setModalOpen={setModalOpen} />
                <SignInModal show={modalOpen} handleClose={() => setModalOpen(false)} />
            </>
        );
    } else if (userState) {
        return (
            <>
                <SignOutButton onSignOut={handleSignOut} />
                <SignInModal show={modalOpen} handleClose={() => setModalOpen(false)} />
            </>
        );
    } else {
        // User not signed in yet
        return null;
    }
}
